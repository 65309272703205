import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faLightbulbOn,
	faFileInvoice,
	faFileContract,
	faCogs,
	faMoneyBill,
	faBadgeDollar,
	faList,
	faFunnelDollar,
	faMobile,
	faWindowAlt,
	faUsers,
	faChartPie,
	faBuilding,
	faHandshake,
	faClipboard,
	faClipboardListCheck,
	faChartLine,
	faChartNetwork,
	faLink,
	faUserChart,
	faLaptopCode,
	faPhoneOffice,
	faHandsUsd,
	faAward,
	faCommentAltEdit,
	faCalendarEdit,
	faClipboardCheck,
	faWaveformPath,
	faPencilRuler,
	faFileCheck,
	faUserHeadset,
	faListAlt,
	faPollH,
	faSmile,
	faStar,
	faTasks,
	faThumbsUp,
	faMegaphone,
	faMailBulk,
	faAnalytics,
	faTrophyAlt,
	faAbacus,
	faHandHoldingSeedling,
	faCommentAltSmile,
	faGlobe,
	faCommentsAlt,
	faCommentAltDots,
	faCommentDiagrams,
	faProjectDiagram,
	faReply,
	faTags,
	faCalendarDay,
	faSeedling,
	faChalkboardTeacher,
	faCreditCard,
	faTruck,
	faClock,
	faUserHardHat,
	faCalendarStar,
	faCalendarCheck,
	faFileSearch,
	faFileShield,
	faSpiderWeb,
	faIdCard,
	faDesktop,
	faSortAmountUpAlt,
	faHandsHelping,
	faBusinessTime,
	faHeadset,
	faPortalExit,
	faFileUser,
	faFileChartPie,
	faBrowser,
	faWandMagic,
	faRocketLaunch,
	faMedal,
	faStore,
	faPhoneAlt,
	faPiggyBank,
	faQuoteRight,
	faWallet,
	faUserCheck,
	faUserShield,
	faAddressBook,
	faHandReceiving,
	faPhonePlus,
	faSquare,
	faSackDollar,
	faSearchLocation,
	faDatabase,
	faSave,
	faBinoculars,
	faDebug,
	faCompass,
	faShieldCheck,
	faSearch,
	faKey,
	faVolumeMute,
	faMobileAndroid,
	faHandHoldingUsd,
	faCheckCircle,
	faShoppingCart,
	faMoneyCheck,
	faMoneyCheckDollarPen,
	faGasPump,
	faCarMechanic,
	faSearchDollar,
	faCars,
	faPhoneVolume,
	faMicrophone,
	faPhoneLaptop,
	faVials,
	faCalculator,
	faFileInvoiceDollar,
	faCalculatorAlt,
	faCommentAltCheck,
	faEnvelope,
	faChartBar,
	faRulerHorizontal,
	faWindow,
	faHouse,
	faCommentsAltDollar,
	faStopwatch,
	faBadgeCheck,
	faFlagUsa,
	faLocationCircle,
	faChartLineDown,
	faReceipt,
	faCarBuilding,
	faBoxCheck,
	faMapLocationDot,
	faCamera,
	faCameraSecurity,
	faSteeringWheel,
	faTools,
	// faTruckFast,
	faVideo,
	faUsdCircle,
	faLocation,
	faBolt,
	faBrain,
	faWifiSlash,
	faMicrochip,
	faSms,
	faHandHoldingDollar,
	faDollarSign,
	faDisplayChartUpCircleDollar,
	faChartPieSimpleCircleDollar,
	faChartMixedUpCircleDollar,
	faArrowsRotate,
	faLockKeyhole,
	faListCheck,
	faMagnifyingGlassChart,
	faBellExclamation,
	faShieldExclamation,
	faSwapArrows,
	faChartLineUp,
	faLaptopMobile,
	faBoxCircleCheck,
	faRotate,
	faBinaryCircleCheck,
	faCalendarClock,
	faCircleNodes,
	faPhone,
  faGamepadModern,
} from '@fortawesome/pro-duotone-svg-icons';
import {
	faSquareFacebook,
	faSquareXTwitter,
	faSquareInstagram,
	faSquareYoutube,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from '../../theme';

import { Nav } from './Nav';
import { Footer } from './Footer';
import 'typeface-poppins';

library.add(
	faLightbulbOn,
	faFileInvoice,
	faFileContract,
	faCogs,
	faMoneyBill,
	faBadgeDollar,
	faList,
	faFunnelDollar,
	faMobile,
	faWindowAlt,
	faUsers,
	faChartPie,
	faBuilding,
	faHandshake,
	faClipboard,
	faClipboardListCheck,
	faChartLine,
	faChartNetwork,
	faLink,
	faUserChart,
	faLaptopCode,
	faPhoneOffice,
	faHandsUsd,
	faAward,
	faCommentAltEdit,
	faCalendarEdit,
	faClipboardCheck,
	faWaveformPath,
	faPencilRuler,
	faFileCheck,
	faUserHeadset,
	faListAlt,
	faPollH,
	faSmile,
	faStar,
	faTasks,
	faThumbsUp,
	faMegaphone,
	faMailBulk,
	faAnalytics,
	faTrophyAlt,
	faAbacus,
	faHandHoldingSeedling,
	faCommentAltSmile,
	faGlobe,
	faCommentsAlt,
	faCommentAltDots,
	faProjectDiagram,
	faReply,
	faTags,
	faCalendarDay,
	faSeedling,
	faChalkboardTeacher,
	faCreditCard,
	faTruck,
	faClock,
	faUserHardHat,
	faCalendarStar,
	faCalendarCheck,
	faFileSearch,
	faFileShield,
	faSpiderWeb,
	faIdCard,
	faDesktop,
	faSortAmountUpAlt,
	faHandsHelping,
	faBusinessTime,
	faHeadset,
	faPortalExit,
	faFileUser,
	faFileChartPie,
	faBrowser,
	faWandMagic,
	faRocketLaunch,
	faMedal,
	faStore,
	faSquareFacebook,
	faSquareXTwitter,
	faSquareInstagram,
	faSquareYoutube,
	faLinkedin,
	faPhoneAlt,
	faPiggyBank,
	faQuoteRight,
	faWallet,
	faUserCheck,
	faUserShield,
	faAddressBook,
	faHandReceiving,
	faPhonePlus,
	faSquare,
	faSackDollar,
	faSearchLocation,
	faDatabase,
	faSave,
	faBinoculars,
	faDebug,
	faCompass,
	faShieldCheck,
	faSearch,
	faKey,
	faVolumeMute,
	faMobileAndroid,
	faCheckCircle,
	faShoppingCart,
	faMoneyCheck,
	faMoneyCheckDollarPen,
	faGasPump,
	faCarMechanic,
	faSearchDollar,
	faCars,
	faPhoneVolume,
	faMicrophone,
	faPhoneLaptop,
	faVials,
	faCalculator,
	faFileInvoiceDollar,
	faCalculatorAlt,
	faCommentAltCheck,
	faEnvelope,
	faChartBar,
	faRulerHorizontal,
	faWindow,
	faHouse,
	faCommentsAltDollar,
	faStopwatch,
	faBadgeCheck,
	faFlagUsa,
	faLocationCircle,
	faHandHoldingUsd,
	faChartLineDown,
	faReceipt,
	faCarBuilding,
	faBoxCheck,
	faMapLocationDot,
	faCamera,
	faSteeringWheel,
	faTools,
	// faTruckFast,
	faVideo,
	faUsdCircle,
	faLocation,
	faBolt,
	faBrain,
	faWifiSlash,
	faMicrochip,
	faSms,
	faHandHoldingDollar,
	faDollarSign,
	faDisplayChartUpCircleDollar,
	faChartPieSimpleCircleDollar,
	faChartMixedUpCircleDollar,
	faArrowsRotate,
	faLockKeyhole,
	faListCheck,
	faCameraSecurity,
	faMagnifyingGlassChart,
	faBellExclamation,
	faShieldExclamation,
	faSwapArrows,
	faChartLineUp,
	faLaptopMobile,
	faBoxCircleCheck,
	faRotate,
	faBinaryCircleCheck,
	faCalendarClock,
	faCircleNodes,
	faPhone,
  faGamepadModern
);

export const Layout = ({ children }) => {
	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			<Nav />
			{children}
			<Footer />
		</MuiThemeProvider>
	);
};
